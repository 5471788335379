import Logo from "./Logo";
import { Icon } from "@iconify/react";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import HeaderLink from "./HeaderLink";

const Header = () => {
  const menuRef = useRef(null);

  const toggleMenu = () => {
    console.log("clicked");
    menuRef.current.classList.toggle("hidden");
  };

  useLayoutEffect(() => {
    document.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        menuRef.current.classList.remove("hidden");
      }
    });
  }, []);

  useEffect(() => {
    menuRef.current.classList.add("hidden");
  });

  const currentPath = useLocation();
  let backgroundColor = "bg-themeWhite";
  let color = "text-black";

  if (currentPath.pathname === "/") {
    backgroundColor = "bg-themeWhite";
    color = "text-black";
  } else {
    backgroundColor = "bg-black";
    color = "text-white";
  }

  const whiteImage = currentPath.pathname === "/" ? false : true;

  return (
    <header className={`Header ${backgroundColor} ${color} px-4 py-2`}>
      <div className="container-fluid">
        <nav className="flex items-center justify-between w-full">
          <div className="flex items-center relative left-[-10px] md:left-0">
            <div className="mr-3 text-theme md:hidden" onClick={toggleMenu}>
              <Icon
                className="hover:cursor-pointer"
                width={30}
                icon="fa-solid:bars"
              />
            </div>
            <Logo white={whiteImage} />
          </div>
          <div
            ref={menuRef}
            className={`absolute hidden z-[2] top-[80px] lg:w-[60%] md:top-0 left-0 right-0 w-full md:w-auto ${backgroundColor} ${color} shadow-md drop-shadow-md rounded-[21px] p-5 md:p-0 md:relative md:shadow-none md:drop-shadow-none md:block md:min-w-[600px] md:mr-36`}
          >
            <ul className="md:ml-[8%] lg:ml-[12%]  flex flex-col md:flex-row items-center justify-between ml-30">
              {/* How It Works */}
              <HeaderLink title="How it Works" to="/how-it-works" />
              {/* About */}
              <HeaderLink title="About Us" to="/about" />
              {/* Support */}
              <HeaderLink title="Educational Center" to="/support" />
              {/* Blog*/}
              <HeaderLink title="Blog" to="/blog" />
              <li className="py-5 mx-2 ml-4 xl:ml-8 min-w-fit">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://affiliate.brandaf.com/"
                  className="btn px-10 rounded-full bg-white text-black border-black border hover:bg-black hover:text-themeWhite hover:border-themeWhite"
                >
                  Get Started
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};
export default Header;
