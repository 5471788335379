import React from 'react'
import { Icon } from '@iconify/react'

export default function SocialLink({url, icon}) {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="SocialLink hover:text-darkPurple inline-block mx-3 first:ml-0 last:mr-0 group transition duration-300">
    <Icon width={30} icon={icon} />
    <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-darkPurple"></span>
  </a>
  )
}
