import { Icon } from "@iconify/react";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import FooterLink from "./FooterLink";
import SocialLink from "./SocialLink";

const Footer = () => {
  return (
    <footer className="pt-16 pb-28 bg-black text-white">
      <div className="container">
        <div className="grid md:grid-cols-7">
          <div className="hidden xl:flex md:col-span-2">
            <div className="content">
              <Logo white={true} />
              <p className="text-[12px] max-w-[270px] leading-[1.6] ml-2 mt-5 mb-10">
                Where Affiliates can gain experience in Affiliate Marketing, and
                where Brands can expand their current Affiliate network.
              </p>

              <div className="relative">
              <SocialLink
                  url="https://www.facebook.com/wearebrandaf"
                  icon="bxl:facebook"
                />

                <SocialLink
                  url="https://www.instagram.com/wearebrandaf/"
                  icon="bxl:instagram"
                />

                <SocialLink
                  url="https://twitter.com/wearebrandaf"
                  icon="bxl:twitter"
                />
              </div>
            </div>
          </div>
          <div className="col-span-7 xl:col-span-5">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-1">
              <div>
                <h5 className="text-[20px] pt-8 pb-4">How it works</h5>
                <ul>
                  <FooterLink to="/" from="affiliates" title="Affiliates" />
                  <FooterLink to="/" from="brands" title="Brands" />
                </ul>
              </div>
              {/* About Section */}
              <div>
                <h5 className="text-[20px] pt-8 pb-4">About Us</h5>
                <ul>
                  <FooterLink to="/about" from="journey" title="Our Journey" />
                  <FooterLink to="/about" from="team" title="Our Team" />
                  <FooterLink to="/about" from="brand" title="Our Brands" />
                </ul>
              </div>
              {/* Support Section */}
              <div>
                <h5 className="text-[20px] pt-8 pb-4">Support</h5>
                <ul>
                  <FooterLink
                    to="/support"
                    from="learning"
                    title="Educational Center"
                    onClick={() => window.scrollTo(0, 0)}
                  />
                  <FooterLink to="/support" from="glossary" title="Glossary" />
                  <FooterLink to="/support" from="faq" title="FAQ" />
                </ul>
              </div>
              {/* Blog Section */}
              <div className="md:pl-10">
                <h5 className="text-[20px] pt-8 pb-4">Blog</h5>
                <ul>
                  <FooterLink to="/blog" from="posts" title="Posts" />
                  <FooterLink to="/blog" from="media" title="Media" />
                  <FooterLink to="/" from="contact" title="Contact Us" />
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1">
          <div className="flex flex-col md:flex-row md:items-center justify-between mt-10 xl:mt-0">
            <div className="md:flex xl:hidden items-center">
              <Logo white={true} />
              <p className="text-[12px] md:hidden max-w-[270px] leading-[1.6] mt-5 mb-10">
                Where Affiliates can gain experience in Affiliate Marketing, and
                where Brands can expand their current Affiliate network.
              </p>
              <div className="relative pl-5 left-[-30px] md:left-0 flex">
                <SocialLink
                  url="https://www.facebook.com/wearebrandaf"
                  icon="bxl:facebook"
                />

                <SocialLink
                  url="https://www.instagram.com/wearebrandaf/"
                  icon="bxl:instagram"
                />

                <SocialLink
                  url="https://twitter.com/wearebrandaf"
                  icon="bxl:twitter"
                />
              </div>
            </div>
            <div className="mt-10 md:mt-0 xl:mt-10 flex items-center">
              <Icon icon="ic:sharp-copyright" />
              <span className="inline-block pl-3 text-xs">
                2022 All Rights Reserved. Terms of Service
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
