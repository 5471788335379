import React from 'react'
import { Link } from "react-router-dom";

export default function HeaderLink({title, to}) {
  return (
    <li className="HeaderLink py-5 mx-2 min-w-fit ">
    <Link
      to={to}
      className="group transition duration-300 lg:text-base">
      {title}
      <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-darkPurple"></span>
    </Link>
  </li>
  )
}
