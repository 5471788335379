import React from 'react'
import { Link } from "react-router-dom";

export default function FooterLink({to, from, title, onClick}) {
  return (
    <li className="pb-5 first:pt-5 group transition duration-300 w-fit whitespace-nowrap" onClick={onClick}>
    <Link
      to={to}
      state={{ from }}
      className="inline-block"
    >
      {title}
    </Link>
    <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-darkPurple"></span>
  </li>
  )
}
