import WelcomeLoader from "../assets/img/loader/rocket.gif";
import Logo from "../assets/img/logo/logo.svg";
import LogoWhite from "../assets/img/logo/logoWhite.svg";
import Girl from "../assets/img/stock/1.png";
import Girl2 from "../assets/img/stock/2.png";
import Girl3 from "../assets/img/stock/3.png";
import Girl4 from "../assets/img/stock/8.png";
import Girl5 from "../assets/img/stock/9.png";
import Star from "../assets/img/stock/1.svg";
import SpaceMan from "../assets/img/stock/spaceman.png";
import SpaceMan2 from "../assets/img/stock/7.svg";
import SearchInfo from "../assets/img/stock/3.svg";
import Rocket from "../assets/img/stock/2.svg";
import Coins from "../assets/img/stock/4.svg";
import CheckList from "../assets/img/stock/5.svg";
import BrandAf from "../assets/img/stock/6.svg";
import Bridge from "../assets/img/stock/5.png";
import People from "../assets/img/stock/6.png";
import Desert from "../assets/img/stock/7.png";
import Henson from "../assets/img/stock/henson.png";
import Hyundai from "../assets/img/stock/fHundayi.png";
import Castle from "../assets/img/stock/castleRock.png";
import HensonNation from "../assets/img/stock/10.png";
import HensonNation2 from "../assets/img/stock/11.png";
import HensonNation3 from "../assets/img/stock/12.png";
import AboutBanner from "../assets/img/stock/floating-spaceman.gif";

import Cole   from "../assets/img/stock/Owner1.jpg";
import Erik   from "../assets/img/stock/Owner2.jpg";
import Paul   from "../assets/img/stock/Owner3.jpg";
import Viviam   from "../assets/img/stock/OurTeam1.png";
import Alyssa   from "../assets/img/stock/OurTeam2.png";
import Justine   from "../assets/img/stock/OurTeam3.png";
import Gabriella   from "../assets/img/stock/OurTeam4.png";


import partneredBrands1   from "../assets/img/stock/PartneredBrands1.png"
import partneredBrands2  from "../assets/img/stock/PartneredBrands2.png"
import partneredBrands3   from "../assets/img/stock/PartneredBrands3.png"
import partneredBrands4   from "../assets/img/stock/PartneredBrands4.png"
import partneredBrands5   from "../assets/img/stock/PartneredBrands5.png"
import partneredBrands6   from "../assets/img/stock/PartneredBrands6.png"
import partneredBrands7   from "../assets/img/stock/PartneredBrands7.png"
import partneredBrands8   from "../assets/img/stock/PartneredBrands8.png"


import starLeftShap from "../assets/img/stock/StarLeftShap.svg"
import starRightShap from "../assets/img/stock/StarRightShap.svg"
import ourJourneyBgShap from "../assets/img/stock/OurJourneyBgShap.svg";
import ourJourneyShap from "../assets/img/stock/OurJourneyShap.svg";
import cloudShap from "../assets/img/stock/CloudShap.svg";



import supportBannerImage from '../assets/img/stock/SupportBannerImage.png'



export const assets = {
  loading: {
    WelcomeLoader,
  },
  brand: {
    Logo,
    LogoWhite,
  },
  stock: {
    Girl,
    Girl2,
    Girl3,
    Girl4,
    Girl5,
    Star,
    SpaceMan,
    SpaceMan2,
    SearchInfo,
    Rocket,
    Coins,
    CheckList,
    BrandAf,
    Bridge,
    People,
    Desert,
    Henson,
    Hyundai,
    Castle,
    HensonNation,
    HensonNation2,
    HensonNation3,
    partneredBrands1,
    partneredBrands2,
    partneredBrands3,
    partneredBrands4,
    partneredBrands5,
    partneredBrands6,
    partneredBrands7,
    partneredBrands8,
    starLeftShap,
    starRightShap,
    ourJourneyBgShap,
    ourJourneyShap,
    cloudShap,
    supportBannerImage
  },

  about: {
    AboutBanner,
  },
  team: {
    Cole,
    Erik,
    Paul,
    Viviam,
    Alyssa,
    Justine,
    Gabriella
  }
};
