import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Master from "./layouts/Master";

const HomePage = lazy(() => import('./pages/home/HomePage'));
const AboutPage = lazy(() => import('./pages/about/AboutPage'));
const HowItWorksPage = lazy(() => import('./pages/how-it-works/HowItActuallyWorks'));
const BlogPage = lazy(() => import('./pages/blog/BlogPage'));

const SupportPage = lazy(() => import('./pages/support/Support'));

const NotFound = lazy(() => import('./components/common/NotFound'));


const Application = () => {
    return (
        <Routes>
            <Route element={<Master />}>
                <Route path="/" element={<Suspense fallback={''}><HomePage /></Suspense>} />
                <Route path="/how-it-works" element={<Suspense fallback={''}><HowItWorksPage /></Suspense>} />
                <Route path="/about" element={<Suspense fallback={''}><AboutPage /></Suspense>} />
                <Route path="/support" element={<Suspense fallback={''}><SupportPage /></Suspense>} />
                <Route path="/blog" element={<Suspense fallback={''}><BlogPage /></Suspense>} />

                <Route path="*" element={<Suspense fallback={''}><NotFound /></Suspense>} />
            </Route>
        </Routes>
    )
}

export default Application;