import {assets} from "../../helpers/assets";
import {Link} from "react-router-dom";

const Logo = ({white=false}) => {
  return(
      white ? (
          <Link to="/" className="inline-block">
              <img className="h-12 md:h-auto  min-w-[120px]  will-change-auto" src={assets.brand.LogoWhite} alt="Brandaf"/>
          </Link>
      ): (
          <Link to="/" className="inline-block">
              <img className="h-12 md:h-auto  min-w-[120px] will-change-auto" src={assets.brand.Logo} alt="Brandaf"/>
          </Link>
      )
  )
}
export default Logo;